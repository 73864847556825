import { Indicator, Pie1D, Pie1DData } from '@salomon/core';
import IndicatorComponent from '../../results/IndicatorComponent';
import Pie1DComponent from '../../results/Pie1DComponent';
import {
  ChartContainer,
  Charts,
  ImpactProject,
  Indicators,
} from '../ImpactReport';
import {
  getNbAthletes,
  getOwner,
  getPie,
  getPiesMergedData,
  getTeams,
  getTotalFromPieData,
  withoutTeam,
} from '../utils';

type Props = {
  projects: ImpactProject[];
  nbAthletes: number;
};

const DetailPerTeam = ({ projects, nbAthletes }: Props) => {
  const teams = getTeams(projects);
  const projectsWithoutTeam = withoutTeam(projects);
  const nbAthletesOfProjectsWOTeam = getNbAthletes(projectsWithoutTeam);
  return (
    <>
      <h3>Detail per team</h3>
      {teams.map((team) => (
        <Team projects={projects} team={team} nbAthletes={nbAthletes} />
      ))}
      <Team
        projects={projectsWithoutTeam}
        nbAthletes={nbAthletesOfProjectsWOTeam}
      />
    </>
  );
};

type TeamProps = {
  projects: ImpactProject[];
  team?: string;
  nbAthletes: number;
};

const Team = ({ projects, team, nbAthletes }: TeamProps) => {
  const pieData = getTeamData(projects, team);
  const { pie, totalIndicator, perAthleteIndicator } = getComponentsFromPieData(
    pieData,
    nbAthletes,
  );
  if (pie.data && getTotalFromPieData(pie.data) === 0) {
    return <></>
  }
  return (
    <>
      <h4>{team ? team : 'No team'}</h4>
      <Indicators>
        <IndicatorComponent result={totalIndicator} />
        <IndicatorComponent result={perAthleteIndicator} />
      </Indicators>
      <Charts>
        <ChartContainer>
          <Pie1DComponent result={pie} />
          <p className="hxr">{pie.title}</p>
        </ChartContainer>
      </Charts>
    </>
  );
};

const getTeamData = (projects: ImpactProject[], team: string | undefined) => {
  const teamProjects = team
    ? projects.filter((project) => {
        const owner = getOwner(project);
        return owner?.user.team === team;
      })
    : projects;
  const pies = teamProjects.map((project) =>
    getPie(project, ['co2_detailed_perso', 'co2_detailed']),
  ) as Pie1D[];
  const pieData = getPiesMergedData(pies);
  return pieData;
};

const getComponentsFromPieData = (pieData: Pie1DData[], nbAthletes: number) => {
  const totalIndicator: Indicator = {
    type: 'indicator',
    title: `total`,
    unit: 'kgCO2',
    number: getTotalFromPieData(pieData).toString(),
  };

  const perAthleteIndicator: Indicator = {
    type: 'indicator',
    title: `per athlete`,
    unit: 'kgCO2',
    number: (getTotalFromPieData(pieData) / nbAthletes).toFixed(1).toString(),
  };

  const pie: Pie1D = {
    type: 'pie1D',
    title: 'per category',
    unit: 'kgCO2',
    data: pieData,
  };

  return { totalIndicator, perAthleteIndicator, pie };
};

export default DetailPerTeam;
