import styled from '@emotion/styled';
import IndicatorComponent from '../../results/IndicatorComponent';
import Pie1DComponent from '../../results/Pie1DComponent';
import {
  ChartContainer,
  Charts,
  ImpactProject,
  Indicators,
} from '../ImpactReport';
import { getComponentsFromPieData, getPie, getPiesMergedData } from '../utils';

type Props = {
  projects: ImpactProject[];
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
`;

export const Element = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const categories = [
  {
    name: 'Housing',
    code: 'co2_accomodation',
  },
  {
    name: 'Mobility',
    code: 'co2_mobility',
  },
];

const DetailCategory = ({ projects }: Props) => {
  return (
    <>
      <h3>Detail per category</h3>
      <Container>
        {categories.map((category) => (
          <Category
            projects={projects}
            name={category.name}
            code={category.code}
          />
        ))}
      </Container>
    </>
  );
};

type SubCategoryProps = {
  projects: ImpactProject[];
  name: string;
  code: string;
};

const Category = ({ projects, name, code }: SubCategoryProps) => {
  const pieData = getCategoryData(projects, code);
  const { pie, indicator } = getComponentsFromPieData(pieData);
  return (
    <Element>
      <h4>{name}</h4>
      <Indicators>
        <IndicatorComponent result={indicator} />
      </Indicators>
      <Charts>
        <ChartContainer>
          <Pie1DComponent result={pie} />
          <p className="hxr">{pie.title}</p>
        </ChartContainer>
      </Charts>
    </Element>
  );
};

const getCategoryData = (projects: ImpactProject[], name: string) => {
  const pies = projects.map((project) => getPie(project, [name]));
  const pieData = getPiesMergedData(pies);
  return pieData;
};

export default DetailCategory;
