import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

interface Props {
  activitySelected: string;
  activityTypes: { code: string; name: string }[];
  onActivityChange: (activity: string) => void;
}

const ActivityTypeSelector = ({
  activitySelected,
  activityTypes,
  onActivityChange,
}: Props) => {
  const handleActivityChange = (event: SelectChangeEvent) => {
    onActivityChange(event.target.value as string);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 180 }}>
      <InputLabel>Activity Type</InputLabel>
      <Select value={activitySelected} onChange={handleActivityChange} label="Activity Type">
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {activityTypes.map((activityType) => (
          <MenuItem key={activityType.code} value={activityType.code}>
            {activityType.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ActivityTypeSelector;
