import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '../../ui/Button';
import { getReadablePlanning } from '../utils';

import { ImpactProject } from '../ImpactReport';

const ListOfActivities = ({
  projects,
  handleClose,
  open,
}: {
  projects: ImpactProject[];
  handleClose: () => void;
  open: boolean;
}) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
      <DialogTitle>List of activities</DialogTitle>
      <DialogContent>
        <p className="hzr">
          The table below lists the activities of Salomon Athletes and indicates
          which ones are included in the report based on the applied filters
        </p>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  '& th': { fontWeight: 'bold', backgroundColor: 'lightgrey' },
                }}
              >
                <TableCell>Name</TableCell>
                <TableCell>Included in report</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Total Impact</TableCell>
                <TableCell>Impact for selected year</TableCell>
                <TableCell>Planning</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow
                  key={project._id.toString()}
                  sx={{
                    backgroundColor: project.isIncluded
                      ? 'lightblue'
                      : 'inherit',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {project.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {project.isIncluded ? 'x' : ''}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {
                      project.users.find((user) => user.role === 'owner')?.user
                        ?.name
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {project.model.singularName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {
                      project.users.find((user) => user.role === 'owner')?.user
                        ?.team
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(project.totalImpact * 10) / 10}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(project.totalImpactWRatio * 10) / 10}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getReadablePlanning(project)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ListOfActivities;
