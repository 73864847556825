import {
  Alert,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  IconButton,
} from '@mui/material';
import * as yup from 'yup';

import HelpIcon from '@mui/icons-material/Help';

import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import useProjects from '../project/context/useProjects';
import Button from '../ui/Button';
import useReports from './context/useReports';
import { GeneralInfos } from './CreateReportModal';

import {
  addDays,
  differenceInCalendarDays,
  getOverlappingDaysInIntervals,
  isDate,
} from 'date-fns';

import { Period, Project } from '@salomon/core';
import { useState } from 'react';
import ReportPeriods, { PeriodExplanation } from './ReportPeriods';

const validationSchema = yup.object({
  projects: yup.array().required('Champ obligatoire'),
});

type ProjectAndValue = {
  id: string;
  value?: number;
};

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  generalInfos: GeneralInfos;
  onCancel: () => void;
  onPrevious: () => void;
}

const ReportActivities = ({ generalInfos, onCancel, onPrevious }: Props) => {
  const navigate = useNavigate();
  const { create } = useReports();
  const { projects } = useProjects();

  let initialValues: ProjectAndValue[] = [] as ProjectAndValue[];

  projects?.forEach((project) => {
    const planning = getPlanning(project);
    const nbReportDays = getNbDaysWithinPeriod(
      planning,
      generalInfos.startDate,
      generalInfos.endDate,
    );
    const nbPlanningDays = getNbPlanningDays(planning);
    if (nbReportDays > 0) {
      initialValues.push({
        id: project._id.toString(),
        value: nbReportDays / nbPlanningDays,
      });
    }
  });

  const formik = useFormik({
    initialValues: { projects: initialValues },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await create({
        ...generalInfos,
        projects: values.projects,
      });
      onCancel();
      navigate(`/reports/${result._id}`);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          component="div"
        >
          <FormGroup sx={{ gap: '1rem' }}>
            {projects?.map((project) => {
              return (
                <>
                  <ProjectRow
                    project={project}
                    formik={formik}
                    generalInfos={generalInfos}
                  />
                </>
              );
            })}
          </FormGroup>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ position: 'sticky', bottom: 0, background: 'white' }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onPrevious}>Previous</Button>
        <Button type="submit" variant="contained" color="primary">
          Next
        </Button>
      </DialogActions>
    </form>
  );
};

const ProjectRowContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  &.activity_disabled {
    background-color: var(--light-grey) !important;
  }
  &.activity_checked {
    background-color: var(--color-secondary) !important;
    color: white;
  }
  &.activity_unchecked {
    padding: 7px;
    border: solid black 1px;
    background-color: white !important;
  }
  > .title {
    width: 200px;
  }
  > :nth-child(3) {
    width: 300px;
  }
  > .data {
    flex: 1;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .activity_success {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const getIsIndicatorNumber = (project: Project): boolean => {
  const indicator = project.data?.mainIndicator;
  const number = convertToNumber(indicator?.number);
  return indicator && number && number !== 0;
};

interface ProjectRowProps {
  project: Project;
  formik: any;
  generalInfos: GeneralInfos;
}

const ProjectRow = ({ project, formik, generalInfos }: ProjectRowProps) => {
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const handleClose = () => setOpenHelp(false);

  const checked = !!(formik.values.projects || []).find(
    (p: ProjectAndValue) => p.id === project._id.toString(),
  );
  const values = formik.values.projects?.find(
    (p: ProjectAndValue) => p.id === project._id.toString(),
  );
  const isIndicatorNumber = getIsIndicatorNumber(project);

  const isPlanningForPeriod =
    project.model.class === 'period' &&
    project.planning &&
    project.planning.length > 0;

  const isPlanningForPunctual =
    project.model.class === 'punctual' &&
    project.data?.startDate &&
    project.data?.endDate;

  const reportStartDate = generalInfos.startDate;
  const reportEndDate = generalInfos.endDate;

  const planning = getPlanning(project);
  const nbReportDays = getNbDaysWithinPeriod(
    planning,
    reportStartDate,
    reportEndDate,
  );
  const nbPlanningDays = getNbPlanningDays(planning);

  const isPlanningDefined =
    (isPlanningForPeriod || isPlanningForPunctual) && nbPlanningDays > 0;
  const isPlanningWithoutReport = isPlanningDefined && nbReportDays === 0;
  const isPlanningWithinReport = isPlanningDefined && nbReportDays > 0;

  const percentageOfProjectWithin = nbReportDays / nbPlanningDays;

  const isDisabled =
    !isIndicatorNumber || !isPlanningDefined || isPlanningWithoutReport;
  const className = isDisabled
    ? 'activity_disabled'
    : checked
    ? 'activity_checked'
    : 'activity_unchecked';

  return (
    <ProjectRowContainer className={className}>
      <PeriodExplanation
        open={openHelp}
        onClose={handleClose}
        project={project}
        planning={planning}
        reportStartDate={reportStartDate}
        reportEndDate={reportEndDate}
      />
      <Checkbox
        disabled={isDisabled}
        checked={isDisabled ? false : checked}
        onChange={(e) => {
          if (e.target.checked) {
            formik.setFieldValue('projects', [
              ...formik.values.projects,
              { id: project._id.toString(), value: nbReportDays },
            ]);
          } else {
            formik.setFieldValue(
              'projects',
              formik.values.projects.filter(
                (p: ProjectAndValue) => p.id !== project._id.toString(),
              ),
            );
          }
        }}
        value={project._id.toString()}
        name="projects"
      />

      <div className="title">
        <p className="h5b" style={{ color: 'black' }}>
          {project.name}
        </p>
        <p className="hxr" style={{ color: 'black' }}>
          {project.model.singularName}
        </p>
      </div>

      {/* PROJECT FEEDBACK */}

      {(!isPlanningDefined || !isIndicatorNumber) && (
        <Alert severity="error" sx={{ alignItems: 'center' }}>
          {!isPlanningDefined && 'Planning not defined'}
          <br />
          {!isIndicatorNumber && 'No Impact'}
        </Alert>
      )}
      {isPlanningWithoutReport && (
        <Alert severity="info">
          Activity planning falls outside report period
        </Alert>
      )}
      {isPlanningWithinReport && isIndicatorNumber && (
        <Alert severity="success" sx={{ alignItems: 'center' }}>
          <div className="activity_success">
            <p className="hxr">
              <b>{Math.round((nbReportDays / nbPlanningDays) * 100)}%</b> of
              this activity is comprised within report period
            </p>
            <IconButton onClick={() => setOpenHelp(true)}>
              <HelpIcon />
            </IconButton>
          </div>
        </Alert>
      )}

      {/* PLANNING ET IMPACT */}

      {isPlanningWithinReport && isIndicatorNumber && (
        <div className="data">
          <ReportPeriods
            nbPlanningDays={nbPlanningDays}
            nbReportDays={nbReportDays}
          />
          <Indicator
            project={project}
            percentageOfProjectWithin={percentageOfProjectWithin}
          />
        </div>
      )}
    </ProjectRowContainer>
  );
};

const convertToNumber = (data: string | number): number | null => {
  if (!data) return null;
  if (typeof data === 'number') {
    return data;
  }
  const parsedNumber = parseFloat(data as string);
  if (isNaN(parsedNumber)) {
    return null;
  }
  return parsedNumber;
};

const IndicatorContainer = styled.p`
  height: 48px;
  display: flex;
  flex-direction: column;
  background-color: lightgreen;
  justify-content: center;
  padding: 0 16px;
  color: black;
`;

const Indicator = ({
  project,
  percentageOfProjectWithin,
}: {
  project: Project;
  percentageOfProjectWithin: number;
}) => {
  const indicator = project.data?.mainIndicator;
  const number = convertToNumber(indicator?.number);
  if (!number) return null;
  const { unit } = indicator;
  return (
    <IndicatorContainer>
      <p className="hxr">
        <b>
          {Math.round(number * percentageOfProjectWithin)} {unit}
        </b>
        {' / '}
        {Math.round(number)}
      </p>
      <p className="hzr">Comprised within report period</p>
    </IndicatorContainer>
  );
};

export const getPlanning = (project: Project): Period[] => {
  if (project.model.class === 'period' && project.planning) {
    return project.planning;
  }
  // project class = punctual
  if (
    project.data &&
    project.data.startDate &&
    isDate(new Date(project.data.startDate)) &&
    project.data.endDate &&
    isDate(new Date(project.data.endDate))
  ) {
    const startDate = new Date(project.data.startDate);
    let endDate = new Date(project.data.endDate);

    if (startDate.getTime() === endDate.getTime()) {
      endDate = addDays(startDate, 1); // Add one day if startDate equals endDate
    }

    return [{ startDate, endDate }];
  }
  return [];
};

const isIntervalCompletelyWithin = (
  interval1: { start: Date; end: Date },
  interval2: { start: Date; end: Date },
) => {
  if (interval1.start <= interval2.start && interval1.end >= interval2.end)
    return true;
  if (interval2.start <= interval1.start && interval2.end >= interval1.end)
    return true;
  return false;
};

export const getNbDaysWithinPeriod = (
  planning: Period[],
  startDate: Date,
  endDate: Date,
): number => {
  return planning?.reduce((acc, planning) => {
    let nb = getOverlappingDaysInIntervals(
      {
        start: new Date(planning.startDate),
        end: new Date(planning.endDate),
      },
      { start: startDate, end: endDate },
    );

    // following line is necessary to correct getOverlappingDaysInIntervals inconsistency :
    // si l'overlap est complet getOverlappingDaysInIntervals renvoie le nb de jours avec start et end inclus
    // si l'overlap est partiel, la même chose moins 1
    const isWithin = isIntervalCompletelyWithin(
      {
        start: new Date(planning.startDate),
        end: new Date(planning.endDate),
      },
      { start: startDate, end: endDate },
    );
    return nb + acc;
  }, 0);
};

export const getNbPlanningDays = (planning: Period[]): number => {
  return planning?.reduce((acc, planning) => {
    const nbDaysOfPeriod = differenceInCalendarDays(
      new Date(planning.endDate),
      new Date(planning.startDate),
    );
    return acc + nbDaysOfPeriod;
  }, 0);
};

export default ReportActivities;
