import { Pie1D } from '@salomon/core';
import IndicatorComponent from '../../results/IndicatorComponent';
import Pie1DComponent from '../../results/Pie1DComponent';
import {
  activityTypes,
  ChartContainer,
  Charts,
  ImpactProject,
  Indicators,
} from '../ImpactReport';
import { getComponentsFromPieData, getPie, getPiesMergedData } from '../utils';
import { Container, Element } from './DetailCategory';

type Props = {
  projects: ImpactProject[];
};

const DetailActivity = ({ projects }: Props) => {
  return (
    <>
      <h3>Detail per activity type</h3>
      <Container>
        {activityTypes.map((activityType) => (
          <Activity
            projects={projects}
            name={activityType.name}
            type={activityType.code}
          />
        ))}
      </Container>
    </>
  );
};

type ActivityProps = {
  projects: ImpactProject[];
  name: string;
  type: string;
};

const Activity = ({ projects, name, type }: ActivityProps) => {
  const piePersonalData = getActivityData(projects, type, 'personal');
  const { pie: piePersonal, indicator: indicatorPersonal } = getComponentsFromPieData(piePersonalData);

  const pieInducedData = getActivityData(projects, type, 'induced');
  const { pie: pieInduced, indicator: indicatorInduced } = getComponentsFromPieData(pieInducedData);
  
  return (
    <>
    <Element>
      <h4>{name} / personal</h4>
      <Indicators>
        <IndicatorComponent result={indicatorPersonal} />
      </Indicators>
      <Charts>
        <ChartContainer>
          <Pie1DComponent result={piePersonal} />
          <p className="hxr">{piePersonal.title}</p>
        </ChartContainer>
      </Charts>
    </Element>
    {type === "race" && <Element>
      <h4>{name} / induced</h4>
      <Indicators>
        <IndicatorComponent result={indicatorInduced} />
      </Indicators>
      <Charts>
        <ChartContainer>
          <Pie1DComponent result={pieInduced} />
          <p className="hxr">{pieInduced.title}</p>
        </ChartContainer>
      </Charts>
    </Element>}
    </>
  );
};

const getActivityData = (projects: ImpactProject[], type: string, resultType: string) => {
  const activityProjects = projects.filter(
    (project) => project.model.type === type,
  );
  const resultsKeys = resultType === 'personal' ? ['co2_detailed_perso', 'co2_detailed'] : ['co2_detailed_induced', 'co2_induced']
  const pies = activityProjects.map((project) =>
    getPie(project, resultsKeys),
  ) as Pie1D[];
  const pieData = getPiesMergedData(pies);
  return pieData;
};

export default DetailActivity;
