import styled from '@emotion/styled';
import { Project } from '@salomon/core';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import AppLayout from '../layout/AppLayout';
import { HeaderProjects as Header } from '../layout/Headers';
import ActivityTypeSelector from './components/ActivityTypeSelector';
import DetailActivity from './components/DetailActivity';
import DetailCategory from './components/DetailCategory';
import DetailPerTeam from './components/DetailPerTeam';
import GeneralStatistics from './components/GeneralStatistics';
import GlobalImpact from './components/GlobalImpact';
import ListOfActivities from './components/ListOfActivities';
import TeamSelector from './components/TeamSelector';
import YearSelector from './components/YearSelector';
import {
  getDataPerYear,
  getFinalProjects,
  getIncludedProjects,
  getNbAthletes,
  getTeams,
  getYearsFrom2023,
} from './utils';

import Button from '../ui/Button';
import useProjects from '../project/context/useProjects';

const Layout = styled.div`
  padding: 16px;
  max-width: 1400px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Filters = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  gap: 16px;
  padding: 16px;
  background-color: white;
`;

const ReportSection = styled.div`
  width: 100%;
  margin: 24px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Indicators = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  > * {
    background-color: white;
    padding: 8px 12px;
    width: auto !important;
  }
`;

export const Charts = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const ChartContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export type ImpactProject = Project & {
  isIncluded: boolean;
  ratio: number;
  totalImpact: number;
  totalImpactWRatio: number;
};

export const activityTypes = [
  {
    code: 'race',
    name: 'Events',
  },
  {
    code: 'preparation',
    name: 'Daily Activities',
  },
];

const ImpactReport = () => {
  const { projects, loading, error } = useProjects({ forImpactReport: true });

  const [year, setYear] = useState<number>(2024);
  const [activitySelected, setActivitySelected] = useState<string>('');
  const [teamSelected, setTeamSelected] = useState<string>('');
  const [isListOfActivities, setIsListOfActivities] = useState<boolean>(false);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{(error as any).message}</p>;
  if (!projects) return <p>An error occurred</p>;

  const years = getYearsFrom2023();
  const finalProjects: ImpactProject[] = getFinalProjects(projects, year);
  const includedProjects: ImpactProject[] = getIncludedProjects(
    finalProjects,
    activitySelected,
    teamSelected,
  );

  const nbProjects = includedProjects.length;
  const nbAthletes = getNbAthletes(includedProjects);
  const teams = getTeams(includedProjects as ImpactProject[]);

  const dataPerYear = getDataPerYear(projects);

  const handleExport = () => {
    const allActivityTypeKeys = new Set<string>();
    const allCategoryKeys = new Set<string>();
    const allTeamKeys = new Set<string>();

    dataPerYear.forEach((item) => {
      Object.keys(item.activityTypes).forEach((key) =>
        allActivityTypeKeys.add(key),
      );
      Object.keys(item.categories).forEach((key) => allCategoryKeys.add(key));
      Object.keys(item.teams).forEach((key) => allTeamKeys.add(key));
    });

    const activityTypeKeys = Array.from(allActivityTypeKeys);
    const categoryKeys = Array.from(allCategoryKeys);
    const teamKeys = Array.from(allTeamKeys);

    const columnTitles = [
      'Year',
      'Total',
      ...activityTypeKeys,
      ...categoryKeys,
      ...teamKeys,
    ];

    const exportData = dataPerYear.map((item) => {
      const row: Record<string, number | string> = {
        Year: item.year,
        Total: item.total,
      };

      activityTypeKeys.forEach((key) => {
        row[key] = item.activityTypes[key] || 0;
      });

      categoryKeys.forEach((key) => {
        row[key] = item.categories[key] || 0;
      });

      teamKeys.forEach((key) => {
        row[key] = item.teams[key] || 0;
      });

      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData, {
      header: columnTitles,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Impact Report');

    XLSX.writeFile(workbook, 'ImpactReport.xlsx');
  };

  return (
    <AppLayout header={<Header />}>
      <ListOfActivities
        open={isListOfActivities}
        projects={finalProjects}
        handleClose={() => setIsListOfActivities(false)}
      />
      <Layout>
        <HeaderContainer>
          <h2>Impact Report</h2>
          <Button onClick={handleExport}>Export</Button>
        </HeaderContainer>

        <Filters>
          <YearSelector year={year} years={years} onYearChange={setYear} />
          <ActivityTypeSelector
            activitySelected={activitySelected}
            activityTypes={activityTypes}
            onActivityChange={setActivitySelected}
          />
          <TeamSelector
            teamSelected={teamSelected}
            teams={teams}
            onTeamChange={setTeamSelected}
          />
        </Filters>
        <p className="hxr">
          <span onClick={() => setIsListOfActivities(true)}>
            <u style={{ cursor: 'pointer' }}>
              {nbProjects} activities filtered
            </u>
          </span>{' '}
          / {finalProjects.length} total activities
        </p>
        <ReportSection>
          <GeneralStatistics
            projects={includedProjects}
            nbProjects={nbProjects}
            nbAthletes={nbAthletes}
          />
        </ReportSection>
        <ReportSection>
          <GlobalImpact
            projects={includedProjects}
            nbProjects={nbProjects}
            nbAthletes={nbAthletes}
          />
        </ReportSection>
        <ReportSection>
          <DetailCategory projects={includedProjects} />
        </ReportSection>
        <ReportSection>
          <DetailActivity projects={includedProjects} />
        </ReportSection>
        <ReportSection>
          <DetailPerTeam projects={includedProjects} nbAthletes={nbAthletes} />
        </ReportSection>
      </Layout>
    </AppLayout>
  );
};

export default ImpactReport;
