import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

interface Props {
  year: number;
  years: number[];
  onYearChange: (newYear: number) => void;
}

const YearSelector = ({ year, years, onYearChange }: Props) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newYear: number) => {
    if (newYear !== null) {
      onYearChange(newYear);
    }
  };

  return (
    <ToggleButtonGroup value={year} exclusive onChange={handleChange}>
      {years.map((y) => (
        <ToggleButton key={y} value={y} aria-label={`${y}`}>
          {y.toString()}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default YearSelector;
