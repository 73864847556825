import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack';
import { useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes } from 'react-router-dom';

import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

import NewProject from './project/NewProject';
import { ProjectForm, ScrollToTopOnce } from './project/Project';

import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material';
import ActionsPage from './actions/page/ActionsPage';
import './App.css';
import AuthenticationGuard from './authentication/AuthenticationGuard';
import {
  AuthenticationProvider,
  useAuthentication,
} from './authentication/context/useAuthentication';
import Home from './authentication/Home';
import Dashboard from './dashboard';
import ImpactReport from './impactReport/ImpactReport';
import AppLayout from './layout/AppLayout';
import { HeaderProject } from './layout/Headers';
import { Tours } from './onboarding/Tours';
import { Provider as BackToProvider } from './project/context/useBackTo';
import Planning from './project/planning/Planning';
import Projects from './projects/Projects';
import Reports from './reports';
import HeaderReports from './reports/Header';
import Report from './reports/Report';
import ReportProjectResults from './reports/ReportProjectResults';
import Results from './results/Results';
import theme from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if ((error as any).message === 'Unauthorized') {
          return false;
        }
        return failureCount < 3;
      },
      refetchOnWindowFocus: false,
    },
  },
});

const AuthCallback = () => {
  const { isAuthenticated, user, loading, handleRedirectCallback } =
    useAuthentication();
  const running = useRef(false);

  const query = window.location.search;
  if (!running.current && query.includes('code=') && query.includes('state=')) {
    running.current = true;
    try {
      handleRedirectCallback();
    } catch (error) {
    } finally {
      window.history.replaceState({}, document.title, '/');
    }
  }

  return null;
};

function App() {
  useEffect(() => {
    if (getCookieConsentValue()) {
      initSentry();
    }
  }, []);

  const initSentry = () => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
      });
    }
  };
  const handleAccept = () => {
    initSentry();
  };

  return (
    <ThemeProvider theme={theme}>
      <CookieConsent
        onAccept={handleAccept}
        flipButtons
        enableDeclineButton
        location="bottom"
        buttonText="Autoriser et fermer"
        declineButtonText="Refuser"
        expires={150}
      >
        SIMULATOR ne fait <b>aucun suivi publicitaire</b> et ne collecte aucune
        données personnelles, hors données d'authentification. Des cookies sont
        utilisés à des fins statistiques ou de fonctionnement, ainsi que
        d'analyse (que vous pouvez refuser ici), nous permettant d'améliorer le
        site en continu.
      </CookieConsent>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
      >
        <AuthenticationProvider>
          <AuthCallback />
          <QueryClientProvider client={queryClient}>
            <BackToProvider>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                style={{ top: '64px' }}
                className="snackbar"
              >
                {/* <AuthenticationGuard suppressError>
                  <Feedback />
                </AuthenticationGuard> */}
                {/* <Tours> */}
                  <Routes>
                    <Route path="/authentication" element={<Home />} />
                    {/* <Route path="/authentication/login" element={<Login />} /> */}
                    {/* <Route
                      path="/authentication/register"
                      element={<Register />}
                    /> */}
                    {/* <Route
                      path="/authentication/recover"
                      element={<Recover />}
                    /> */}
                    {/* <Route
                      path="/authentication/recover/:email"
                      element={<Recover />}
                    /> */}
                    {/* <Route
                      path="/authentication/reset-password/:token"
                      element={
                        <ResetPassword
                          title="Réinitialisation mot de passe"
                          fieldLabel="Nouveau mot de passe"
                          successMessage="Mot de passe réinitialisé avec succès"
                          buttonLabel="Réinitialiser"
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/authentication/confirm-account/:token"
                      element={
                        <ResetPassword
                          title="Création de compte"
                          fieldLabel="Mot de passe"
                          successMessage="Compte créé avec succès"
                          buttonLabel="Créer"
                        />
                      }
                    /> */}

                    <Route
                      path="/"
                      element={
                        <AuthenticationGuard>
                          <Dashboard />
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/projects"
                      element={
                        <AuthenticationGuard>
                          <Projects />
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/reports"
                      element={
                        <AuthenticationGuard>
                          <AppLayout header={<HeaderReports />}>
                            <ScrollToTopOnce />
                            <Reports />
                          </AppLayout>
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/reports/:reportId"
                      element={
                        <AuthenticationGuard>
                          <AppLayout header={<HeaderReports />}>
                            <ScrollToTopOnce />
                            <Report />
                          </AppLayout>
                        </AuthenticationGuard>
                      }
                    />
                    <Route
                      path="/reports/:reportId/:projectId"
                      element={
                        <AuthenticationGuard>
                          <AppLayout header={<HeaderReports />}>
                            <ScrollToTopOnce />
                            <ReportProjectResults />
                          </AppLayout>
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/project"
                      element={
                        <AuthenticationGuard>
                          <NewProject />
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/project/:projectId/"
                      element={
                        <AuthenticationGuard>
                          <AppLayout header={<HeaderProject />}>
                            <ProjectForm />
                          </AppLayout>
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/project/:projectId/planning"
                      element={
                        <AuthenticationGuard>
                          <AppLayout header={<HeaderProject />}>
                            <Planning />
                          </AppLayout>
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/project/:projectId/results"
                      element={
                        <AuthenticationGuard>
                          <AppLayout header={<HeaderProject />}>
                            <ScrollToTopOnce />
                            <Results />
                          </AppLayout>
                        </AuthenticationGuard>
                      }
                    />
                    <Route
                      path="/project/:projectId/actions"
                      element={
                        <AuthenticationGuard>
                          <AppLayout header={<HeaderProject />}>
                            <ScrollToTopOnce />
                            <ActionsPage />
                          </AppLayout>
                        </AuthenticationGuard>
                      }
                    />

                    <Route
                      path="/impact-report"
                      element={
                        <AuthenticationGuard>
                          <ImpactReport />
                        </AuthenticationGuard>
                      }
                    />
                  </Routes>
                {/* </Tours> */}
                <ReactQueryDevtools initialIsOpen={false} />
              </SnackbarProvider>
            </BackToProvider>
          </QueryClientProvider>
        </AuthenticationProvider>
      </Auth0Provider>
    </ThemeProvider>
  );
}

export default App;
