import { Indicator, Pie1D, Pie1DData } from '@salomon/core';
import IndicatorComponent from '../../results/IndicatorComponent';
import Pie1DComponent from '../../results/Pie1DComponent';
import {
  ChartContainer,
  Charts,
  ImpactProject,
  Indicators,
} from '../ImpactReport';
import {
  activityColors,
  colors,
  getCategoryData,
  getOwner,
  getProjectsTotalImpact,
  getTeams,
} from '../utils';

type Props = {
  projects: ImpactProject[];
  nbProjects: number;
  nbAthletes: number;
};

const GlobalImpact = ({ projects, nbProjects, nbAthletes }: Props) => {
  const totalImpact = projects.reduce(
    (total, project) => total + project.totalImpactWRatio,
    0,
  );
  const totalIndicator: Indicator = {
    type: 'indicator',
    title: 'total',
    unit: 'kgCO2',
    number: totalImpact.toFixed(1).toString(),
  };

  const perActivityIndicator: Indicator = {
    type: 'indicator',
    title: 'per activity',
    unit: 'kgCO2',
    number: (totalImpact / nbProjects).toFixed(1).toString(),
  };

  const perAthleteIndicator: Indicator = {
    type: 'indicator',
    title: 'per athlete',
    unit: 'kgCO2',
    number: (totalImpact / nbAthletes).toFixed(1).toString(),
  };

  // Per category pie
  const perCategoryData = getCategoryData(projects);
  const perCategoryPie: Pie1D = {
    type: 'pie1D',
    title: 'per category',
    unit: 'kgCO2',
    data: perCategoryData,
  };

  //Per activity type pie
  const perActivityData: Pie1DData[] = [
    {
      name: 'Daily Activities',
      fill: activityColors['preparation'],
      value: getProjectsTotalImpact(
        projects.filter((project) => project.model.type === 'preparation'),
      ),
    },
    {
      name: 'Events',
      fill: activityColors['race'],
      value: getProjectsTotalImpact(
        projects.filter((project) => project.model.type === 'race'),
      ),
    },
  ];
  const perActivityPie: Pie1D = {
    type: 'pie1D',
    title: 'per activity type',
    unit: 'kgCO2',
    data: perActivityData,
  };

  //Per team
  const teams = getTeams(projects);
  const perTeamData: Pie1DData[] = teams.map((team, i) => {
    const name = team ? team : 'No team';
    return {
      name,
      fill: colors[i],
      value: getProjectsTotalImpact(
        projects.filter((project) => {
          const owner = getOwner(project);
          if (owner) return owner.user.team === team;
          return false;
        }),
      ),
    };
  });
  const perTeamPie: Pie1D = {
    type: 'pie1D',
    title: 'per team',
    unit: 'kgCO2',
    data: perTeamData,
  };

  return (
    <>
      <h3>Global Impact (personal only)</h3>
      <Indicators>
        <IndicatorComponent result={totalIndicator} />
        <IndicatorComponent result={perActivityIndicator} />
        <IndicatorComponent result={perAthleteIndicator} />
      </Indicators>
      <Charts>
        <ChartContainer>
          <Pie1DComponent result={perCategoryPie} />
          <p className="hxr">{perCategoryPie.title}</p>
        </ChartContainer>
        <ChartContainer>
          <Pie1DComponent result={perActivityPie} />
          <p className="hxr">{perActivityPie.title}</p>
        </ChartContainer>
        <ChartContainer>
          <Pie1DComponent result={perTeamPie} />
          <p className="hxr">{perTeamPie.title}</p>
        </ChartContainer>
      </Charts>
    </>
  );
};

export default GlobalImpact;
