import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  teamSelected: string;
  teams: string[];
  onTeamChange: (team: string) => void;
}

const TeamSelector = ({ teamSelected, teams, onTeamChange }: Props) => {
  const handleTeamChange = (event: SelectChangeEvent) => {
    onTeamChange(event.target.value as string);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 180 }}>
      <InputLabel>Team</InputLabel>
      <Select value={teamSelected} onChange={handleTeamChange} label="Team">
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {teams.map((team) => (
          <MenuItem key={team} value={team}>
            {team}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TeamSelector;
