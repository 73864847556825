import { Indicator, Pie1D, Pie1DData } from '@salomon/core';
import IndicatorComponent from '../../results/IndicatorComponent';
import Pie1DComponent from '../../results/Pie1DComponent';
import {
  ChartContainer,
  Charts,
  ImpactProject,
  Indicators,
} from '../ImpactReport';
import {
  activityColors,
  addColors,
  countOccurrences,
  getTeams,
} from '../utils';

type Props = {
  projects: ImpactProject[];
  nbProjects: number;
  nbAthletes: number;
};

const GeneralStatistics = ({ projects, nbProjects, nbAthletes }: Props) => {
  const nbAthletesIndicator: Indicator = {
    type: 'indicator',
    title: 'athletes',
    number: nbAthletes.toString(),
  };
  const nbActivityPerAthleteIndicator: Indicator = {
    type: 'indicator',
    title: 'activity / athlete',
    number: (Math.round((nbProjects / nbAthletes) * 10) / 10).toString(),
  };

  // Activity Per Type Pie

  const activityPerTypePieData: Pie1DData[] = [
    {
      name: 'Daily Activities',
      fill: activityColors['preparation'],
      value: projects.filter((project) => project.model.type === 'preparation')
        .length,
    },
    {
      name: 'Events',
      fill: activityColors['race'],
      value: projects.filter((project) => project.model.type === 'race').length,
    },
  ];

  const activityPerTypePie: Pie1D = {
    type: 'pie1D',
    title: 'Activity / Type',
    data: activityPerTypePieData,
  };

  // Athletes per Team

  const teams = getTeams(projects).map((team) => team ? team : "No team");
  const nbAthletesPerTeam = countOccurrences(teams);
  const athletesPerTeampData = addColors(nbAthletesPerTeam);

  const athletesPerTeampPie: Pie1D = {
    type: 'pie1D',
    title: 'Athletes / Team',
    data: athletesPerTeampData,
  };

  return (
    <>
      <h3>General Statistics</h3>
      <Indicators>
        <IndicatorComponent result={nbAthletesIndicator} />
        <IndicatorComponent result={nbActivityPerAthleteIndicator} />
      </Indicators>
      <Charts>
        <ChartContainer>
          <Pie1DComponent result={activityPerTypePie} />
          <p className="hxr">{activityPerTypePie.title}</p>
        </ChartContainer>
        <ChartContainer>
          <Pie1DComponent result={athletesPerTeampPie} />
          <p className="hxr">{athletesPerTeampPie.title}</p>
        </ChartContainer>
      </Charts>
    </>
  );
};

export default GeneralStatistics;
