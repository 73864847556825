import {
  Indicator,
  Period,
  Pie1D,
  Pie1DData,
  Project,
  ProjectUser,
  Result,
} from '@salomon/core';
import { endOfYear, startOfYear } from 'date-fns';
import { mergePieData } from '../dashboard/blocks/impact/ImpactBlock';
import {
  getNbDaysWithinPeriod,
  getNbPlanningDays,
  getPlanning,
} from '../reports/ReportActivities';
import { ImpactProject } from './ImpactReport';

export const activityColors = {
  preparation: 'var(--orange)',
  race: 'var(--blue)',
};

export const colors = [
  'var(--grey)',
  'var(--brown)',
  'var(--pink)',
  'var(--red)',
  'var(--orange)',
  'var(--purple)',
  'var(--lightpurple)',
  'var(--blue)',
  'var(--lightblue)',
  'var(--brightgreen)',
  'var(--brightlightgreen)',
];

type Occurence = { name: string; value: number };

export const getComponentsFromPieData = (pieData: Pie1DData[]) => {
  const indicator: Indicator = {
    type: 'indicator',
    title: `total`,
    unit: 'kgCO2',
    number: getTotalFromPieData(pieData).toString(),
  };

  const pie: Pie1D = {
    type: 'pie1D',
    title: 'per category',
    unit: 'kgCO2',
    data: pieData,
  };

  return { pie, indicator };
};

export const countOccurrences = (arr: string[]) => {
  const result: Occurence[] = [];
  const countMap: { [key: string]: number } = {};
  arr.forEach((str) => {
    countMap[str] = (countMap[str] || 0) + 1;
  });
  for (const [name, value] of Object.entries(countMap)) {
    result.push({ name, value });
  }
  return result;
};

export const addColors = (occurrences: Occurence[]) => {
  const occurencesWColors = occurrences.map((occurence, i) => {
    let occurenceWColor = {
      ...occurence,
      fill: colors[i],
    };
    return occurenceWColor;
  });
  return occurencesWColors;
};

export const getUniqueUsers = (users: ProjectUser[]): ProjectUser[] => {
  const emailSet = new Set<string>();
  const uniqueUsers: ProjectUser[] = [];

  users.forEach((user) => {
    if (!emailSet.has(user.user?.email)) {
      emailSet.add(user.user?.email);
      uniqueUsers.push(user);
    }
  });
  return uniqueUsers;
};

export const getOwner = (project: Project): ProjectUser | undefined => {
  return project.users.find((user) => user.role === 'owner');
};

export const getOwners = (projects: Project[]): ProjectUser[] => {
  return projects
    .map(getOwner)
    .filter((user) => user !== undefined) as ProjectUser[];
};

export const withoutTeam = (projects: ImpactProject[]) => {
  return projects.filter((project) => {
    const owner = getOwner(project);
    return !owner?.user?.team;
  });
};

export const getTeams = (projects: ImpactProject[]) => {
  const owners = getOwners(projects);
  const uniqueOwners = getUniqueUsers(owners);
  const teams = uniqueOwners.map((owner) => owner?.user?.team);
  return teams;
};

export const getNbAthletes = (projects: Project[]) => {
  const owners = getOwners(projects);
  const ownersEmail = owners
    .map((owner) => owner?.user?.email)
    .filter((email) => email !== undefined);
  return [...new Set(ownersEmail)].length;
};

export const getTotalFromPieData = (pieData: Pie1DData[]) => {
  const total = pieData.reduce((total, data) => total + data.value, 0);
  return total;
};

export const getPie = (project: ImpactProject, codes: string[]) => {
  const pie: Pie1D = project.results.find(
    (result) =>
      result.type === 'pie1D' && result.code && codes.includes(result.code),
  ) as Pie1D;
  return pie;
};

export const getPiesMergedData = (pies: Pie1D[]) => {
  const piesData: Pie1DData[][] = pies.reduce(
    (piesTemp: Pie1DData[][], pie: Pie1D) => {
      return pie?.data ? [...piesTemp, pie.data] : piesTemp;
    },
    [],
  );
  const mergedData = mergePieData(piesData);
  return mergedData;
};

//Get impact (without ratio) of a project, from indicator
export const getProjectImpact = (project: Project) => {
  if (project.model.type === 'preparation') {
    const result = project.results?.find(
      (result: Result) => result.type === 'indicator' && result.code === 'co2',
    );
    const impact =
      result && 'number' in result ? parseFloat(result.number!) : 0;
    return impact;
  } else if (project.model.type === 'race') {
    // customer wants only the personal emissions and not the induced, and the 'co2' indicator for race is personal + induced : total personal is the total of the personal pie
    const result = project.results?.find(
      (result: Result) =>
        result.type === 'pie1D' && result.code === 'co2_detailed_perso',
    ) as Pie1D | undefined;
    const impact =
      result && result.data
        ? result.data.reduce((total, row) => row.value + total, 0)
        : 0;
    return impact;
  }
  return 0;
};

//Get impact (with ratio) of a list of project
export const getProjectsTotalImpact = (projects: ImpactProject[]) => {
  const total = projects.reduce(
    (total, project) => total + project.totalImpactWRatio,
    0,
  );
  return total;
};

export const getYearsFrom2023 = () => {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  for (let year = 2023; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};

export const getReadablePlanning = (project: ImpactProject) => {
  const planning = getPlanning(project);
  return planning.reduce((text: string, period: Period, i: number) => {
    const readablePeriod =
      new Date(period.startDate).toLocaleDateString() +
      ' > ' +
      new Date(period.endDate).toLocaleDateString();
    return i === planning.length - 1
      ? text + readablePeriod
      : text + readablePeriod + ', ';
  }, '');
};

const isOfAthlete = (project: Project) => {
  const owner = getOwner(project);
  if (owner && owner.user && owner.user.type) {
    return owner.user.type === 'athlete';
  }
  return false;
};

export const getFinalProjects = (projects: Project[], year: number) => {
  //filter relevant projects
  const relevantProjets = projects.filter(isOfAthlete);

  //add relevant data
  const startDate = startOfYear(new Date(year, 0, 1));
  const endDate = endOfYear(new Date(year, 11, 31));
  const finalProjects = relevantProjets.map((project) => {
    const planning = getPlanning(project);
    const nbReportDays = getNbDaysWithinPeriod(planning, startDate, endDate);
    const nbPlanningDays = getNbPlanningDays(planning);
    const ratio = nbPlanningDays ? nbReportDays / nbPlanningDays : 0;
    const totalImpact = getProjectImpact(project);
    const totalImpactWRatio = totalImpact * ratio;
    const resultsWRatio = getResultsWRatio(project.results, ratio);
    return {
      ...project,
      results: resultsWRatio,
      ratio,
      totalImpact,
      totalImpactWRatio,
      isIncluded: ratio > 0 && totalImpact > 0,
    };
  });
  return finalProjects;
};

const getResultsWRatio = (results: Result[], ratio: number) => {
  let resultsWRatio: Result[] = [];
  results.forEach((result) => {
    if (result.type === 'indicator') {
      let indicator = JSON.parse(JSON.stringify(result));
      indicator.number =
        indicator.number !== undefined
          ? (parseFloat(indicator.number) * ratio).toString()
          : '0';
      resultsWRatio.push(indicator);
    } else if (result.type === 'pie1D') {
      let pie1D = JSON.parse(JSON.stringify(result));
      if (pie1D.data) {
        pie1D.data = pie1D.data.map((row: Pie1DData) => ({
          ...row,
          value: row.value * ratio,
        }));
      }
      resultsWRatio.push(pie1D);
    } else {
      resultsWRatio.push(result);
    }
  });
  return resultsWRatio;
};

export const getCategoryData = (projects: ImpactProject[]) => {
  const pies: Pie1D[] = projects.map((project) =>
    getPie(project, ['co2_detailed_perso', 'co2_detailed']),
  ) as Pie1D[];
  const mergedData = getPiesMergedData(pies);
  return mergedData;
};

export const getIncludedProjects = (
  projects: ImpactProject[],
  activitySelected: string,
  teamSelected: string,
) => {
  const includedProjects = projects.filter((project) => {
    const isOfActivitySelected =
      !activitySelected || project.model.type === activitySelected;
    const owner = getOwner(project);
    const isOfTeamSelected =
      !teamSelected ||
      (owner &&
        owner.user &&
        owner.user.team &&
        owner.user.team === teamSelected);
    return project.isIncluded && isOfActivitySelected && isOfTeamSelected;
  });
  return includedProjects;
};

interface YearlyData {
  year: number;
  total: number;
  activityTypes: {
    [key: string]: number;
  };
  categories: {
    [key: string]: number;
  };
  teams: {
    [key: string]: number;
  };
}

export const getDataPerYear = (projects: Project[]) => {
  const years = getYearsFrom2023();
  const dataPerYear: YearlyData[] = years.map((year) => {
    const finalProjects: ImpactProject[] = getFinalProjects(projects, year);
    const includedProjects: ImpactProject[] = getIncludedProjects(
      finalProjects,
      '',
      '',
    );

    //TOTAL
    const total = includedProjects.reduce(
      (total, project) => total + project.totalImpactWRatio,
      0,
    );

    //PER EVENT TYPE
    const activityTypes = {
      'Daily Activities': getProjectsTotalImpact(
        includedProjects.filter(
          (project) => project.model.type === 'preparation',
        ),
      ),
      Events: getProjectsTotalImpact(
        includedProjects.filter((project) => project.model.type === 'race'),
      ),
    };

    //CATEGORIES
    const perCategoryData = getCategoryData(includedProjects);
    const categories: { [key: string]: number } = {};
    perCategoryData.forEach((category) => {
      categories[category.name] = category.value; // Dynamically set the key
    });

    //TEAMS
    const teamsName = getTeams(includedProjects);
    const teams: { [key: string]: number } = {};
    teamsName.forEach((team) => {
      const name = team ? team : 'No team';
      teams[name] = getProjectsTotalImpact(
        includedProjects.filter((project) => {
          const owner = getOwner(project);
          if (owner) return owner.user.team === team;
          return false;
        }),
      );
    });

    return {
      year,
      total,
      activityTypes,
      categories,
      teams,
    };
  });
  return dataPerYear;
};
